<template>
    <div>
        <v-card class="pa-2 mb-2">
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                    hide-details
                    outlined
                    clearable
                    dense
                    label="Number"
                    :disabled="isLoading"
                    v-model="searchFilters.number"
                    @keydown.enter="updateSearch"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                    hide-details
                    outlined
                    clearable
                    dense
                    label="Name"
                    :disabled="isLoading"
                    v-model="searchFilters.name"
                    @keydown.enter="updateSearch"
                />
              </v-col>

                <v-col cols="12" sm="4">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isBusy"
                            @click="updateSearch"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>
      <PaginationCardV2
          :page="searchFilters.page"
          :totalRecords="searchResults.total_records"
          :totalPages="searchResults.total_pages"
          :limit="searchFilters.limit"
          :currentPageTotal="(searchResults.results) ? searchResults.results.length : 0"
          :loading="isBusy"
          @updatedPagination="updatedPagination"
          :no-skeleton="!firstLoad"
      >
        <v-simple-table>
          <thead>
          <tr>
            <th class="text-center">Ref ID</th>
            <th class="text-center">Number</th>
            <th class="text-center">Name</th>
            <th></th>
          </tr>
          </thead>

          <v-slide-x-transition tag="tbody" hide-on-leave group>
            <tr v-if="searchResults.results && searchResults.results.length === 0" key="nodata">
              <td colspan="100%" class="text-center">
                No results found
              </td>
            </tr>
            <tr
                v-for="item in searchResults.results"
                :key="item.id"
                v-bind:class="{
                            /*'red darken-4':
                                $vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',
                            'red lighten-4':
                                !$vuetify.theme.dark && item.bill_status !== 'C' && item.blocked === 'Y',*/
                            'grey darken-3':
                                $vuetify.theme.dark && !ringGroupAvailable(item),
                            'grey lighten-3':
                                !$vuetify.theme.dark && !ringGroupAvailable(item),
                        }"
            >
              <td class="text-center">
                <router-link
                    :to="{
                                    name: 'TrunkSummary',
                                    params: {
                                        customerId: item.i_customer,
                                        trunkId: item.bc_i_account,
                                    },
                                }"
                    class="font-weight-medium"
                >{{ item.i_c_group }}</router-link>
              </td>
              <td class="text-center">{{item.id}}</td>
              <td class="text-center">{{item.name}}</td>
              <td class="text-center">
                <v-btn
                    v-if="ringGroupAvailable(item)"
                    height="40"
                    :loading="isLoading"
                    @click="selectRingGroup(item)"
                >Select</v-btn>
                <span v-else>Already has Call Queue</span>
              </td>

            </tr>
          </v-slide-x-transition>
        </v-simple-table>
      </PaginationCardV2>
    </div>
</template>

<script>
import TrunkStatus from '../../pieces/Trunk/TrunkStatus';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import PaginationCardV2 from "../templates/PaginationCardV2";
export default {
    name: 'CallQueueRingGroupSelectListCard',
    mixins: [apiMixin, refreshListenerMixin],
    data: () => ({
      firstLoad: false,
      isLoading:false,
      currentCallQueues:[],
      searchFilters: {
          name:null,
          number:null,
            page:1,
            limit:10,
        },
      searchResults:{
        total_records: 0,
        total_pages:0,
        results:[],
      },

    }),
    components: {
      PaginationCardV2,
    },
    props: {
        i_customer: {
          type: Number,
          default: null,
        },
        hidePagination: {
            type: Boolean,
            default: false,
        },
        custId: {
            type: Number,
            default: null,
        },
        ringGroupId: {
          type: Number,
          default: null,
        },
        showAddress: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
  computed: {
      isBusy( ){
        return this.isLoading;
      }
  },

    methods: {
      ringGroupAvailable(ringGroup)
      {
        if(this.currentCallQueues == null){
          return true;
        }
        let id = ringGroup.i_c_group;
        if(this.currentCallQueues.find(element => element.i_c_group == id) == null){
          return true;
        }else{
          return false;
        }
      },

      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.getRingGroupList( );
      },
      updateSearch(data){
        this.searchFilters.page = 1;
        this.getRingGroupList( );
      },

      update( )
      {
        this.getRingGroupList( );
        this.getCallQueueList();
      },

      async getRingGroupList() {
        if(this.currentCallQueues == null){
          this.getCallQueueList();
        }

        this.isLoading = true;
        let params = { };
        params.i_customer = this.i_customer;

        if(this.searchFilters.name != null){
          params.name = this.searchFilters.name;
        }

        if(this.searchFilters.number != null){
          params.number = this.searchFilters.number;
        }

        let queryString = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');

        const response = await this.Api.send('get','call-queues/available-hunt-groups?'+queryString);

        this.isLoading = false;
        if (response.success) {
          this.searchResults = response.data;
          /*this.searchResults.results = response.data.data;
          this.searchResults.total_records = response.data.count;
          this.searchResults.total_pages = response.data.pages;*/
          if (this.firstLoad) this.firstLoad = false;
        } else {
          this.searchResults.results = [];
        }

      },

    async getCallQueueList() {
        this.isLoading = true;
        const response = await this.Api.send('get','customers/'+this.i_customer+'/call-queues');
        this.isLoading = false;
        this.currentCallQueues = response.data.results;
      },

      async  selectRingGroup(item){
        this.$emit('selected', item);
      }
    },
};
</script>
