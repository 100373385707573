<template>
    <BasicCard
        :loading="loading || isLoading"
        :no-skeleton="!firstLoad"
        :title="data.account_type_label+' Registration Status'"
        v-bind:class="{
            'error darken-4':
                $vuetify.theme.dark && statusData.sip_status === 0,
            'error lighten-4':
                !$vuetify.theme.dark && statusData.sip_status === 0,
            'success darken-4':
                $vuetify.theme.dark && statusData.sip_status === 1,
            'success lighten-4':
                !$vuetify.theme.dark && statusData.sip_status === 1,
        }"
    >
        <v-window v-model="registration">
            <v-window-item :value="1">
                <p
                    class="title text--primary mt-1"
                    v-if="statusData.sip_status !== 1"
                >
                    Unregistered
                </p>

                <p
                    class="title text--primary mt-1"
                    v-if="statusData.sip_status === 1"
                >
                    Registered
                    <LocalDateTime
                        :value="statusData.sip_info.last_modified"
                        relative
                        hide-tooltip
                    />
                    <span v-if="totalRegistrations > 1">
                        (1 of {{ totalRegistrations }})</span
                    >
                </p>

                <v-row dense v-if="statusData.sip_info">
                    <v-col cols="4">
                        <span class="font-weight-bold">Last Registered</span>
                    </v-col>
                    <v-col cols="8">
                        <LocalDateTime
                            :value="statusData.sip_info.last_modified"
                            show-time
                            show-seconds
                            hide-tooltip
                        />
                    </v-col>
                    <v-col cols="4">
                        <span class="font-weight-bold">Expires</span>
                    </v-col>
                    <v-col cols="8">
                        <LocalDateTime
                            :value="statusData.sip_info.expires"
                            show-time
                            show-seconds
                            hide-tooltip
                        />
                    </v-col>
                    <v-col cols="4">
                        <span class="font-weight-bold">Contact</span>
                    </v-col>
                    <v-col cols="8">
                        {{ statusData.sip_info.contact }}
                    </v-col>
                    <v-col cols="4">
                        <span class="font-weight-bold">User Agent</span>
                    </v-col>
                    <v-col cols="8">
                        {{ statusData.sip_info.user_agent }}
                    </v-col>
                    <v-col cols="4">
                        <span class="font-weight-bold">Domain</span>
                    </v-col>
                    <v-col cols="8">
                        {{ statusData.sip_info.domain }}
                    </v-col>
                </v-row>

                <v-row dense v-if="statusData.sip_status !== 1">
                    <v-col cols="12"> No status information available </v-col>
                </v-row>
            </v-window-item>
            <v-window-item
                v-for="(item, index) in statusData.additional_sip_info_list"
                v-bind:key="item.callid"
                :value="index + 2"
            >
                <p class="title text--primary mt-1">
                    Registered
                    <LocalDateTime
                        :value="item.last_modified"
                        relative
                        hide-tooltip
                    />
                    <span> ({{ index + 2 }} of {{ totalRegistrations }})</span>
                </p>

                <v-row dense>
                    <v-col cols="4">
                        <span class="font-weight-bold">Last Registered</span>
                    </v-col>
                    <v-col cols="8">
                        <LocalDateTime
                            :value="item.last_modified"
                            show-time
                            show-seconds
                            hide-tooltip
                        />
                    </v-col>
                    <v-col cols="4">
                        <span class="font-weight-bold">Expires</span>
                    </v-col>
                    <v-col cols="8">
                        <LocalDateTime
                            :value="item.expires"
                            show-time
                            show-seconds
                            hide-tooltip
                        />
                    </v-col>
                    <v-col cols="4">
                        <span class="font-weight-bold">Contact</span>
                    </v-col>
                    <v-col cols="8">
                        {{ item.contact }}
                    </v-col>
                    <v-col cols="4">
                        <span class="font-weight-bold">User Agent</span>
                    </v-col>
                    <v-col cols="8">
                        {{ item.user_agent }}
                    </v-col>
                    <v-col cols="4">
                        <span class="font-weight-bold">Domain</span>
                    </v-col>
                    <v-col cols="8">
                        {{ item.domain }}
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
        <template v-slot:feature>
            <v-icon x-large color="success" v-if="statusData.sip_status === 1"
                >mdi-check-circle</v-icon
            >
            <v-icon x-large color="error" v-if="statusData.sip_status !== 1"
                >mdi-alert-circle</v-icon
            >
        </template>
        <template v-slot:actions>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn
                        v-if="data.i_account_role === 1 && false"
                        text
                        v-on="on"
                        link
                        v-bind:href="
                            'https://admin.breezeconnect.com.au:8558/ui/trace_session/list;session_type=5;interval=60;account_id=' +
                            data.id
                        "
                        target="_blank"
                        color="primary"
                    >
                        <v-icon left>mdi-open-in-new</v-icon> Trace
                    </v-btn>
                </template>
                <span
                    >Trace registrations for this account in PortaOne
                    Admin</span
                >
            </v-tooltip>
            <ButtonWithTooltip
                :disabled="isLoading"
                text="Refresh"
                icon="mdi-refresh"
                flat
                tooltip="Refresh registration status info"
                @click="update"
            />
            <ButtonWithTooltip
                v-if="totalRegistrations > 1"
                :text="$vuetify.breakpoint.xsOnly ? undefined : 'Prev'"
                icon="mdi-chevron-left"
                flat
                @click="registration = registration - 1"
                :disabled="registration <= 1"
            />
            <ButtonWithTooltip
                v-if="totalRegistrations > 1"
                :text="$vuetify.breakpoint.xsOnly ? undefined : 'Next'"
                icon="mdi-chevron-right"
                icon-right
                flat
                @click="registration++"
                :disabled="registration >= totalRegistrations"
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import apiMixin from '../../../mixins/apiMixin';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
export default {
    name: 'TrunkRegistrationCard',
    mixins: [apiMixin, refreshListenerMixin],
    components: { ButtonWithTooltip, LocalDateTime, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
            isLoading: false,
            registration: 1,
            statusData: [],
            firstLoad: true,
        };
    },
    watch: {},
    methods: {
        async get(id) {
            this.isLoading = true;
            const response = await this.Api.send('post','trunks/' + id + '/status');
            this.isLoading = false;
            this.firstLoad = false;
            if (response.success) {
                this.statusData = response.data;
            } else {
                this.statusData = [];
            }
        },
        update() {
            this.registration = 1;
            this.get(this.$route.params.trunkId);
        },
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
    computed: {
        totalRegistrations: function () {
            if (this.busy) return 0;
            if (this.statusData.sip_status !== 1) return 0;
            return (
                this.statusData.sip_status +
                (this.statusData.additional_sip_info_list
                    ? this.statusData.additional_sip_info_list.length
                    : 0)
            );
        },
    },
    created() {
        this.update();
    },
};
</script>
